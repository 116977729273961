import {AddCircle} from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {EnumSelectInput} from "../../base/inputs/EnumSelectInput";
import {LanguageSwitcher} from "../../base/inputs/LanguageSwitcher";
import {TextInput} from "../../base/inputs/TextInput";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {languageConfigurations, useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {QuestionnairesProvider} from "../../domain/QuestionnairesProvider";
import {AnswerType, MultilingualTextWrapper, Question, QuestionEditRequest, Questionnaire} from "../../domain/types";
import {gs} from "../../theme";

interface QuestionEditProps {}

export function QuestionEdit(props: QuestionEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const navigate = useNavigate();

    const {t, es, language} = useLanguageContext();

    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    const [question, setQuestion] = useState<Question>();

    const [addLanguageDialogOpened, setAddLanguageDialogOpened] = useState(false);

    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

    const [languages, setLanguages] = useState<string[]>();

    const [preCustomLanguage, setPreCustomLanguage] = useState("");
    const [customLanguage, setCustomLanguage] = useState("");

    const [loading, setLoading] = useState(false);

    const defaultForm: QuestionEditRequest = {
        descriptions: [],
        required: false,
        titles: [],
        type: AnswerType.Boolean
    };

    const [form, setForm] = useState<QuestionEditRequest>(defaultForm);
    const [selectedLanguage, setSelectedLanguage] = useState(language);

    const handleFormChange = (field: string, value: any) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value
        }));
    };

    useEffect(() => {
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.get(tenantUuid, routeParams.slug).then(setQuestionnaire);
            questionnairesProvider.getQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid).then((res) => {
                setQuestion(res);
                setForm({
                    descriptions: res.descriptions,
                    required: res.required,
                    titles: res.titles,
                    type: res.type
                });

                let langs: string[] = [];
                res.titles.map((title) => langs.push(title.locale));
                setLanguages(langs);
            });
        }
    }, []);

    useEffect(() => {
        if (customLanguage) {
            setSelectedLanguage(customLanguage);
            const langs = languages || [];
            langs.push(customLanguage);

            if (languages && !languages.includes(customLanguage)) {
                setLanguages([...new Set([...languages, customLanguage])]);
            }

            const newTitleExists = form.titles.some((item) => item.locale === customLanguage);

            if (!newTitleExists) {
                const newTitle = {locale: customLanguage, text: ""};
                handleFormChange("titles", [...form.titles, newTitle]);
            }

            const newDescriptionExists = form.descriptions.some((item) => item.locale === customLanguage);

            if (!newDescriptionExists) {
                const newDescription = {locale: customLanguage, text: ""};
                handleFormChange("descriptions", [...form.descriptions, newDescription]);
            }
        }
    }, [customLanguage]);

    const handleChangeTitle = (title: string) => {
        const existingTitle = form.titles.find((item) => item.text === title);
        if (existingTitle) {
            handleFormChange(
                "titles",
                form.titles.map((item) => (item.locale === selectedLanguage ? {...item, text: title} : item))
            );
        } else {
            handleFormChange("titles", [
                ...form.titles.filter((item) => item.locale !== selectedLanguage),
                {locale: selectedLanguage, text: title}
            ]);
        }
    };

    const handleChangeDescription = (description: string) => {
        const existingDescription = form.descriptions.find((item) => item.text === description);
        if (existingDescription) {
            handleFormChange(
                "descriptions",
                form.descriptions.map((item) =>
                    item.locale === selectedLanguage ? {...item, text: description} : item
                )
            );
        } else {
            handleFormChange("descriptions", [
                ...form.descriptions.filter((item) => item.locale !== selectedLanguage),
                {locale: selectedLanguage, text: description}
            ]);
        }
    };

    const handleEditQuestion = () => {
        const questionnairesProvider = new QuestionnairesProvider(api);
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            setLoading(true);
            questionnairesProvider
                .editQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid, form)
                .then(() => {
                    setLoading(false);
                })
                .finally(() => handleCancelEdit());
        }
    };

    const handleDeleteQuestion = () => {
        const questionnairesProvider = new QuestionnairesProvider(api);
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            questionnairesProvider
                .deleteQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid)
                .then((res) => {
                    setLoading(false);
                    setDeleteDialogOpened(false);
                })
                .finally(() => handleCancelEdit());
        }
    };

    const handleCancelEdit = () => {
        navigate(`/tenants/${tenantUuid}/questionnaires/${routeParams.slug}`);
    };

    const deriveOptionLabel = (key: string | null) => {
        if (key) {
            return es("languages", key);
        }
        return "";
    };

    const isValid = useMemo(() => {
        const titles = form.titles.map((title) => title.text);
        const titlesValid = titles.every((title) => title != "");

        return titlesValid != false;
    }, [form]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={question ? new MultilingualTextWrapper(question.titles).resolved(language) : "..."}
                        crumbs={[
                            {label: t("questionnaires.plural"), href: `/tenants/${tenantUuid}/questionnaires`},
                            {
                                label: questionnaire
                                    ? new MultilingualTextWrapper(questionnaire.titles).resolved(language)
                                    : "...",
                                href: `/tenants/${tenantUuid}/questionnaires/${routeParams.slug}`
                            }
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BoxedPaper loading={loading}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={8}>
                                        <TextInput
                                            label={t("title")}
                                            onChange={(e) => handleChangeTitle(e)}
                                            value={new MultilingualTextWrapper(form.titles).resolved(selectedLanguage)}
                                        />
                                        {selectedLanguage !== language && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {question
                                                        ? new MultilingualTextWrapper(question.titles).resolved(
                                                              language
                                                          )
                                                        : "..."}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LanguageSwitcher
                                            key={JSON.stringify(customLanguage)}
                                            onChange={(e, f) => setSelectedLanguage(e)}
                                            languages={
                                                customLanguage && languages
                                                    ? [
                                                          ...(Array.isArray(languages)
                                                              ? languages.filter((lang) => lang !== customLanguage)
                                                              : []),
                                                          customLanguage
                                                      ]
                                                    : Array.isArray(languages)
                                                    ? languages
                                                    : []
                                            }
                                            customLanguageSelected={customLanguage}
                                        />
                                        <IconButton
                                            size="large"
                                            title={t("languages.add")}
                                            onClick={() => setAddLanguageDialogOpened(true)}
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    label={t("description")}
                                    onChange={(e) => handleChangeDescription(e)}
                                    value={new MultilingualTextWrapper(form.descriptions).resolved(selectedLanguage)}
                                    rows={4}
                                />
                                {selectedLanguage !== language && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            {question
                                                ? new MultilingualTextWrapper(question.descriptions).resolved(language)
                                                : "..."}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={6}>
                                        <EnumSelectInput
                                            label={t("type")}
                                            onChange={(e: AnswerType) => {
                                                handleFormChange("type", e);
                                            }}
                                            lookupKey="questionnaires.types"
                                            options={Object.values(AnswerType)}
                                            value={form.type}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.required}
                                                    onChange={(event) =>
                                                        handleFormChange("required", event.target.checked)
                                                    }
                                                    color="primary"
                                                />
                                            }
                                            label={t("questionnaires.required")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box p={3} />

                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid
                                        item
                                        sx={{
                                            flexGrow: "1"
                                        }}
                                    >
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleEditQuestion()}
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!isValid}
                                                >
                                                    {t("save")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setDeleteDialogOpened(true)}
                                                    color="error"
                                                    variant="outlined"
                                                    disabled={loading}
                                                >
                                                    {t("delete")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => handleCancelEdit()} variant="outlined">
                                            {t("cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
            {addLanguageDialogOpened && (
                <Dialog
                    open={addLanguageDialogOpened}
                    onClose={() => setAddLanguageDialogOpened(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>{t("languages.add")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={languageConfigurations.map((lc) => lc.language)}
                                    getOptionLabel={(key) => deriveOptionLabel(key)}
                                    style={{width: "100%"}}
                                    value={preCustomLanguage}
                                    disableClearable
                                    onChange={(event: any, newValue: string | null) => {
                                        if (newValue) {
                                            setPreCustomLanguage(newValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                marginTop: "20px"
                                            }}
                                            label={t("languages.add")}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setCustomLanguage(preCustomLanguage);
                                setAddLanguageDialogOpened(false);
                                setPreCustomLanguage("");
                            }}
                            variant="contained"
                            color="primary"
                        >
                            {t("confirm")}
                        </Button>
                        <Button onClick={() => setAddLanguageDialogOpened(false)} variant="outlined">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {deleteDialogOpened && (
                <Dialog open={deleteDialogOpened} onClose={() => setDeleteDialogOpened(false)} fullWidth maxWidth="sm">
                    <DialogTitle>{t("delete")}</DialogTitle>
                    <DialogContent>{t("questionnaires.deleteQuestion.message")}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDeleteQuestion()} variant="contained" color="error">
                            {t("delete")}
                        </Button>
                        <Button onClick={() => setDeleteDialogOpened(false)} variant="outlined">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                    {loading && <LinearProgress />}
                </Dialog>
            )}
        </Container>
    );
}
