import * as React from "react";
import {useLanguageContext} from "../context/language/LanguageContext";
import {alpha, Autocomplete, Chip, Grid, styled, TextField, Typography} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {SearchElementWrapper, SearchResponseElement} from "../domain/types";
import {SearchProvider} from "../domain/SearchProvider";
import {useAuthContext} from "../context/auth/AuthContext";
import {useTenantContext} from "../context/tenant/TenantContext";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../theme";
import {useNavigate} from "react-router-dom";

interface GlobalSearchInputProps {
}
const StyledInputBase = styled(TextField)(({theme}) => ({
    color: "inherit",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    "& .MuiOutlinedInput-root": {
        transition: theme.transitions.create("width"),
        width: "20ch",
        color: "inherit",
        border: "0px"
    },
    "& .Mui-focused": {
        width: "40ch"
    }
}));

export function GlobalSearchInput(props: GlobalSearchInputProps) {
    const {language} = useLanguageContext();
    const {auth, api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {es} = useLanguageContext();

    const [needle, setNeedle] = useState<string>("");
    const [options, setOptions] = useState<SearchResponseElement[]>();

    useEffect(() => {
        if (api.auth && auth.isLoggedIn && needle.length > 2) {
            const searchProvider = new SearchProvider(api);
            searchProvider.search(tenantUuid, {language, needle}).then((results) => {
                setOptions(results.results);
            });
        }
    }, [api, tenantUuid, needle, language]);

    const handleSelect = useCallback((value: SearchResponseElement | string | null) => {
        if (value && typeof value !== "string") {
            setNeedle("");
            setOptions(undefined);
            const wrapper = new SearchElementWrapper(value);
            navigate(wrapper.route(tenantUuid));
        }
    }, [tenantUuid, navigate]);

    return (
        <Autocomplete
            sx={{padding: theme.spacing(1)}}
            freeSolo
            blurOnSelect
            options={options || []}
            getOptionLabel={(option: SearchResponseElement | string) => {
                if (typeof option === "string") {
                    return option;
                }
                return option.display
            }}
            getOptionKey={(option: SearchResponseElement | string) => {
                if (typeof option === "string") {
                    return option;
                }
                return option.path;
            }}
            renderInput={(params) =>
                <StyledInputBase
                    {...params}
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <SearchIcon />
                        )
                    }}
                />
            }
            renderOption={(props, option) =>
                <li {...props}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant="body1">
                                {option?.display || ""}
                            </Typography>
                        </Grid>
                        {option &&
                            <Grid item>
                                <Typography variant="body2">
                                    <Chip label={es("entities", option.entity)} size="small" />
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </li>
        }
            inputValue={needle}
            onInputChange={(e, value) => setNeedle(value)}
            onChange={(e, value) => handleSelect(value)}
        />
    );
}
