import React, {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";
import {DateDisplayFormat} from "../../libs/tools";

export interface LayoutContextData {
    title: string;
    setTitle: (title: string) => void;
    drawerNavOpen: boolean;
    setDrawerNavOpen: (open: boolean) => void;
    dateDisplayFormat: DateDisplayFormat;
    setDateDisplayFormat: (format: DateDisplayFormat) => void;
}

const emptyContext: LayoutContextData = {
    title: "Artiligence",
    setTitle: (title: string) => {},
    drawerNavOpen: false,
    setDrawerNavOpen: (open: boolean) => {},
    dateDisplayFormat: DateDisplayFormat.Age,
    setDateDisplayFormat: () => {}
};

export const LayoutContext = createContext<LayoutContextData>(emptyContext);

interface LayoutContextProps extends PropsWithChildren<{}> {}

export function LayoutContextProvider(props: LayoutContextProps) {
    const {children} = props;

    const [title, setTitle] = useState<string>(emptyContext.title);
    const [drawerNavOpen, setDrawerNavOpen] = useState<boolean>(emptyContext.drawerNavOpen);

    const [dateDisplayFormat, setDateDisplayFormat] = useState<DateDisplayFormat>(DateDisplayFormat.Age);

    const handleSetTitle = useCallback((newTitle: string) => {
        setTitle(newTitle);
        document.title = `${newTitle} - Artiligence`;

        const storedValue = localStorage.getItem("loupe-date-display-format");
        if (storedValue) {
            setDateDisplayFormat(storedValue as DateDisplayFormat);
        }
    }, []);

    const handleDisplayFormatChanged = useCallback((format: DateDisplayFormat) => {
        setDateDisplayFormat(format);
        localStorage.setItem("loupe-date-display-format", format);
    }, []);

    const currentContext = useMemo<LayoutContextData>(
        () => ({
            title,
            setTitle: handleSetTitle,
            drawerNavOpen,
            setDrawerNavOpen,
            dateDisplayFormat: dateDisplayFormat,
            setDateDisplayFormat: handleDisplayFormatChanged
        }),
        [title, handleSetTitle, drawerNavOpen, setDrawerNavOpen, dateDisplayFormat, handleDisplayFormatChanged]
    );

    return <LayoutContext.Provider value={currentContext}>{children}</LayoutContext.Provider>;
}

export function useLayoutContext(): LayoutContextData {
    return useContext<LayoutContextData>(LayoutContext);
}
