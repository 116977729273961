import React, {useEffect, useState} from "react";
import {Route} from "react-router";
import {Routes, useParams} from "react-router-dom";
import {TenantContextProvider} from "./context/tenant/TenantContext";
import {Index} from "./pages/Index";
import {AssetAdd} from "./pages/assets/AssetAdd";
import {AssetEdit} from "./pages/assets/AssetEdit";
import {AssetView} from "./pages/assets/AssetView";
import {AssetsList} from "./pages/assets/AssetsList";
import {AssetAddMeasure} from "./pages/assets/edit/AssetAddMeasure";
import {AssetAddRisk} from "./pages/assets/edit/AssetAddRisk";
import {AssetEditRisk} from "./pages/assets/edit/AssetEditRisk";
import {MeasureEdit} from "./pages/assets/edit/MeasureEdit";
import {AuthLogin} from "./pages/auth/AuthLogin";
import {AuthLogout} from "./pages/auth/AuthLogout";
import {AuthProcessToken} from "./pages/auth/AuthProcessToken";
import {TenantSelect} from "./pages/current-user/TenantSelect";
import {UserProfile} from "./pages/current-user/UserProfile";
import {Dashboard} from "./pages/dashboard/Dashboard";
import Demo from "./pages/demo/Demo";
import {QuestionEdit} from "./pages/questionnaires/QuestionEdit";
import {QuestionnaireView} from "./pages/questionnaires/QuestionnaireView";
import {QuestionnairesList} from "./pages/questionnaires/QuestionnairesList";
import {RoleView} from "./pages/roles/RoleView";
import {RolesList} from "./pages/roles/RolesList";
import {ToolEdit} from "./pages/tools/ToolEdit";
import {ToolView} from "./pages/tools/ToolView";
import {ToolsList} from "./pages/tools/ToolsList";
import {UserView} from "./pages/users/UserView";
import {UsersList} from "./pages/users/UsersList";

interface RoutesProps {}

export function AppRoutes(props: RoutesProps) {
    return (
        <Routes>
            <Route path="/current-user/*" element={<CurrentUserRoutes />} />
            <Route path="/tenants/:tenantUuid/*" element={<TenantRoutes />} />
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/" element={<Index />} />
            <Route path="*" element={<div>404</div>} />
        </Routes>
    );
}

export function CurrentUserRoutes(props: RoutesProps) {
    return (
        <Routes>
            <Route path="/tenant-select" element={<TenantSelect />} />
            <Route path="/profile" element={<UserProfile />} />
        </Routes>
    );
}

export function TenantRoutes(props: RoutesProps) {
    const routeParams = useParams();
    const [tenantUuid, setTenantUuid] = useState<string>();

    useEffect(() => {
        if (routeParams.tenantUuid) {
            setTenantUuid(routeParams.tenantUuid);
        }
    }, []);

    if (!tenantUuid) {
        return null;
    }

    return (
        <TenantContextProvider tenantUuid={tenantUuid}>
            <Routes>
                <Route path="/tools/:kiToolUuid/edit" element={<ToolEdit />} />
                <Route path="/tools/:kiToolUuid" element={<ToolView />} />
                <Route path="/tools" element={<ToolsList />} />

                <Route path="/assets/:assetUuid/measures/add" element={<AssetAddMeasure />} />
                <Route path="/assets/:assetUuid/measures/:measureUuid" element={<MeasureEdit />} />
                <Route path="/assets/:assetUuid/risks/add" element={<AssetAddRisk />} />
                <Route path="/assets/:assetUuid/risks/:riskUuid" element={<AssetEditRisk />} />
                <Route path="/assets/:assetUuid/edit" element={<AssetEdit />} />
                <Route path="/assets/:assetUuid" element={<AssetView />} />
                <Route path="/assets/add" element={<AssetAdd />} />
                <Route path="/assets" element={<AssetsList />} />

                <Route path="/questionnaires/:slug" element={<QuestionnaireView />} />
                <Route path="/questionnaires" element={<QuestionnairesList />} />

                <Route path="/questionnaires/:slug/:questionUuid/edit" element={<QuestionEdit />} />

                <Route path="/users/:username" element={<UserView />} />
                <Route path="/users" element={<UsersList />} />

                <Route path="/roles/:roleUuid" element={<RoleView />} />
                <Route path="/roles" element={<RolesList />} />

                <Route path="/" element={<Dashboard />} />
            </Routes>
        </TenantContextProvider>
    );
}

export function AuthRoutes(props: RoutesProps) {
    return (
        <Routes>
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/process-token" element={<AuthProcessToken />} />
            <Route path="/logout" element={<AuthLogout />} />
        </Routes>
    );
}
