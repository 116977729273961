import {Api} from "../libs/Api";
import {defaultPage, Page, PageChange, Question, QuestionEditRequest, Questionnaire} from "./types";

export class QuestionnairesProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<Questionnaire>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/questionnaires`, pageChange);
        return this.api.get<Page<Questionnaire>>(url);
    }

    get(tenantUuid: string, slug: string): Promise<Questionnaire> {
        const url = `/api/v1/tenants/${tenantUuid}/questionnaires/${slug}`;
        return this.api.get<Questionnaire>(url);
    }

    async findDefault(tenantUuid: string): Promise<Questionnaire> {
        const page = await this.list(tenantUuid, defaultPage);
        // TODO more checking?
        return page.content[0];
    }

    async questions(tenantUuid: string, slug: string): Promise<Question[]> {
        const url = `/api/v1/tenants/${tenantUuid}/questionnaires/${slug}/questions`;
        return this.api.get<Question[]>(url);
    }

    // questions
    async getQuestion(tenantUuid: string, slug: string, questionUuid: string): Promise<Question> {
        const url = `/api/v1/tenants/${tenantUuid}/questionnaires/${slug}/questions/${questionUuid}`;
        return this.api.get<Question>(url);
    }

    async editQuestion(tenantUuid: string, slug: string, questionUuid: string, request: QuestionEditRequest) {
        const url = `/api/v1/tenants/${tenantUuid}/questionnaires/${slug}/questions/${questionUuid}`;
        return this.api.put<Question>(url, request);
    }

    async deleteQuestion(tenantUuid: string, slug: string, questionUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/questionnaires/${slug}/questions/${questionUuid}`;
        return this.api.delete(url);
    }
}
