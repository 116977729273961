import * as Handlebars from "handlebars";
import * as React from "react";
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";

import de from "../../i18n/de.json";
import en from "../../i18n/en.json";

interface LanguageConfiguration {
    language: string;
    fallback?: string;
    strings: any;
    decimalSeparator: string;
    thousandsSeparator: string;
}

export const languageConfigurations: LanguageConfiguration[] = [
    {language: "en", decimalSeparator: ".", thousandsSeparator: ",", strings: en},
    {language: "bg", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "bg"},
    {language: "cs", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "cs"},
    {language: "da", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "da"},
    {language: "de", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: de},
    {language: "el", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "el"},
    {language: "es", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "es"},
    {language: "fr", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "fr"},
    {language: "hr", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "hr"},
    {language: "hu", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "hu"},
    {language: "it", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "it"},
    {language: "ko", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "ko"},
    {language: "nl", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "nl"},
    {language: "no", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "no"},
    {language: "pl", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "pl"},
    {language: "ro", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "ro"},
    {language: "sk", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "sk"},
    {language: "sl", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "sl"},
    {language: "sr", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "sr"},
    {language: "sv", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "sv"},
    {language: "th", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "th"},
    {language: "tr", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "tr"},
    {language: "uk", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "uk"},
    {language: "ru", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "ru"},
    {language: "zh", decimalSeparator: ",", thousandsSeparator: ".", fallback: "en", strings: "zh"}
];

type keys = typeof en;

function isObject(item: any): boolean {
    return item && typeof item === "object" && !Array.isArray(item);
}

function mergeDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

class LanguageResolver {
    readonly language: string;
    readonly strict: boolean;
    readonly fallbackLanguage?: string;
    readonly strings: any;
    readonly fallbackStrings?: any;
    private configuration?: LanguageConfiguration;

    constructor(language: string, overrides?: string) {
        this.language = language;
        this.strict = window.location.hostname == "localhost";
        this.configuration = LanguageResolver.lookupLanguageConfiguration(this.language);
        if (!this.configuration) {
            console.warn(`Cannot find language configuration for ${this.language} using default of en.`);
            this.configuration = LanguageResolver.lookupLanguageConfiguration("en");
        }
        if (!this.configuration) {
            throw new Error(`No language configuration for 'en' found, cannot continue.`);
        }
        this.strings = this.configuration.strings;
        const languageOverrides = overrides ? overrides[this.language as keyof typeof overrides] : undefined;
        if (overrides && languageOverrides) {
            mergeDeep(this.strings, languageOverrides);
        }
        if (this.configuration.fallback) {
            let fallback = LanguageResolver.lookupLanguageConfiguration(this.configuration.fallback);
            if (fallback) {
                this.fallbackLanguage = fallback.language;
                this.fallbackStrings = fallback.strings;
            }
        }
    }

    private static lookupLanguageConfiguration(language: string): LanguageConfiguration | undefined {
        return languageConfigurations.filter((c) => c.language == language).pop();
    }

    private static processHandleBarsTemplate(key: string, template: string, object?: any): string {
        if (!object) {
            console.warn(
                `Tried resolving a handlebars template for '${key}' and template ${template}, however, no data object was supplied, returning template`
            );
            return template;
        }
        const compiledTemplate = Handlebars.compile(template);
        return compiledTemplate(object);
    }

    private resolveWithArrayInternally(
        strings: any,
        key: string,
        keyElements: string[],
        object?: any
    ): string | undefined {
        const firstKey = keyElements.shift() as keyof typeof strings;
        const resolved = strings[firstKey];
        if (resolved != undefined) {
            if (typeof resolved == "string") {
                if (resolved.indexOf("{{") != -1) {
                    return LanguageResolver.processHandleBarsTemplate(key, resolved, object);
                } else {
                    return resolved as string;
                }
            } else {
                if (keyElements.length > 0) {
                    return this.resolveWithArrayInternally(resolved, key, keyElements, object);
                } else {
                    return resolved;
                }
            }
        }
        return undefined;
    }

    private resolveInternally(strings: any, key: string, object?: any): string | object | undefined {
        const keyElements = key.split(".");
        const resolved = this.resolveWithArrayInternally(strings, key, keyElements, object);
        if (resolved != undefined) {
            return resolved;
        }
        return undefined;
    }

    resolve(key: string, object?: any): string | object {
        const primaryLanguage = this.resolveInternally(this.strings, key, object);
        if (primaryLanguage != undefined) {
            return primaryLanguage;
        }
        if (this.fallbackStrings) {
            console.warn(
                `The key ${key} did not resolve for the primary language '${this.language}', looking up in fallback language '${this.fallbackLanguage}'`
            );
            const fallbackLanguage = this.resolveInternally(this.fallbackStrings, key, object);
            if (fallbackLanguage != undefined) {
                return fallbackLanguage;
            }
        }
        const errorMessage = `Could not look up key ${key} for language ${this.language}`;
        if (this.strict) {
            throw new Error(errorMessage);
        } else {
            console.error(errorMessage);
            return key;
        }
    }

    get decimalSeparator(): string {
        if (!this.configuration) {
            throw new Error(`No language configuration for '${this.language}' found, cannot continue.`);
        }
        return this.configuration.decimalSeparator;
    }

    get thousandsSeparator(): string {
        if (!this.configuration) {
            throw new Error(`No language configuration for '${this.language}' found, cannot continue.`);
        }
        return this.configuration.thousandsSeparator;
    }
}

const LANGUAGE_KEY = "artiligence-language";

class PreferredLanguage {
    get readLocalStorage(): string | null {
        return localStorage.getItem(LANGUAGE_KEY);
    }

    get readBrowserDefault(): string {
        return navigator.language || (navigator as any).userLanguage || "en";
    }

    set language(language: string) {
        localStorage.setItem(LANGUAGE_KEY, language);
    }

    get language(): string {
        const language = this.readLocalStorage || this.readBrowserDefault;
        return language.slice(0, 2);
    }
}

const preferredLanguage = new PreferredLanguage();

export class LanguageSupport {
    readonly languages?: string[];

    constructor(languages?: string[]) {
        this.languages = languages;
    }

    supports(language: string): boolean {
        if (this.languages && this.languages.length > 0) {
            return this.languages.indexOf(language) != -1;
        }
        return true;
    }

    get defaultSupportedLanguage() {
        const current = preferredLanguage.language;
        if (this.supports(preferredLanguage.language)) {
            return current;
        }
        const browser = preferredLanguage.readBrowserDefault;
        if (this.supports(browser)) {
            return browser;
        }
        if (this.languages && this.languages.length > 0) {
            return this.languages[0];
        }
        return "en";
    }
}

export interface LanguageContextData {
    language: string;
    setLanguage: (language: string) => void;
    setOverrides: (overrides?: string) => void;
    t: (key: string, object?: any) => string;
    obj: (key: string) => string | object;
    e: (key: string, e: string) => string | object;
    es: (key: string, e: string) => string;
    languageResolver?: LanguageResolver;
    decimalSeparator: string;
    thousandsSeparator: string;
}

const emptyContext: LanguageContextData = {
    language: preferredLanguage.language,
    setLanguage: (language: string) => {
        console.error("setLanguage called on non-initialized LanguageContext");
    },
    setOverrides: (language: string | undefined) => {
        console.error("setOverrides called on non-initialized LanguageContext");
    },
    t: (key: string) => {
        return "-- loading --";
    },
    obj: (key: string) => {
        return "-- loading --";
    },
    e: (key: string) => {
        return "-- loading --";
    },
    es: (key: string, e: string) => {
        return "-- loading --";
    },
    decimalSeparator: ".",
    thousandsSeparator: ","
};

export const LanguageContext = createContext(emptyContext);

export function LanguageContextProvider({children}: PropsWithChildren<any>) {
    const [language, setLanguage] = useState<string>(preferredLanguage.language);
    const [overrides, setOverrides] = useState<any>();

    useEffect(() => {
        const elements = document.getElementsByTagName("html");
        for (let i = 0; i < elements.length; i++) {
            elements[i].setAttribute("lang", language);
        }
    }, [language]);

    const handleSetLanguage = useCallback((nextLanguage: string) => {
        const currentLanguage = preferredLanguage.language;
        if (currentLanguage != nextLanguage) {
            preferredLanguage.language = nextLanguage;
            setLanguage(nextLanguage);
        }
    }, []);

    const handleSetOverrides = useCallback((overrides?: string) => {
        if (overrides) {
            try {
                const parsed = JSON.parse(overrides);
                setOverrides(parsed);
                return;
            } catch (e) {
                console.error("Could not parse language overrides");
                console.error(e);
            }
        }
        setOverrides(undefined);
    }, []);

    const translate = useCallback(
        (resolver: LanguageResolver, key: string, object?: any): string => {
            return resolver.resolve(key, object) as string;
        },
        [language]
    );

    const lookupObject = useCallback(
        (resolver: LanguageResolver, key: string, object?: any): string | object => {
            return resolver.resolve(key, object);
        },
        [language]
    );

    const translateEnum = useCallback(
        (resolver: LanguageResolver, key: string, e: string): string | object => {
            const object = resolver.resolve(key);
            if (typeof object == "string") {
                throw new Error(`The key ${key} is not denoting an object, cannot resolve enum constant ${e}`);
            }
            const enumKey = e as keyof typeof object;
            const ret = object[enumKey];
            if (!ret) {
                console.warn(`Cannot resolve key ${e} over ${key}. Key resolved to ${object}`);
            }
            return ret;
        },
        [language]
    );

    const defaultContext = useMemo<LanguageContextData>(() => {
        const languageResolver = new LanguageResolver(language, overrides);

        return {
            language: language,
            setLanguage: handleSetLanguage,
            setOverrides: handleSetOverrides,
            t: (key: string, object?: any) => translate(languageResolver, key, object),
            obj: (key: string) => lookupObject(languageResolver, key),
            e: (key: string, e: string) => translateEnum(languageResolver, key, e),
            es: (key: string, e: string) => translateEnum(languageResolver, key, e) as string,
            languageResolver: languageResolver,
            decimalSeparator: languageResolver.decimalSeparator,
            thousandsSeparator: languageResolver.thousandsSeparator
        };
    }, [language, overrides, setLanguage, translate]);

    return <LanguageContext.Provider value={defaultContext}>{children}</LanguageContext.Provider>;
}

export function useLanguageContext(): LanguageContextData {
    return useContext<LanguageContextData>(LanguageContext);
}
